<template>
  <template v-if="status === 'error'">
    <CaEmptyState
      title="Ops, lista de benefícios indisponível"
      variant="horizontal"
    />
  </template>
  <div
    v-else
    class="benefits"
  >
    <div class="benefits__background" />
    <div class="benefits__title">
      <CaTitle
        tag="h2"
        class="font-black text-center font-title-s pt-4 pb-6"
        weight="black"
        color="primary"
        :size="isDesktop ? 'm' : 's'"
      >
        {{ benefits?.title }}
      </CaTitle>
    </div>

    <div
      ref="illustrations"
      class="benefits__illustrations"
      @scrollend.passive="handleScroll"
    >
      <Benefit
        v-for="(item, index) in benefitsItems"
        :key="index"
        :item="item"
        :is-active="currentIndex === index"
        class="benefits__illustrations__item"
        @click="handleIllustrationClick(index, item.title)"
      />
    </div>
    <div
      ref="cards"
      class="benefits__cards"
      @scrollend.passive="handleScroll"
    >
      <Card
        v-for="(item, index) in benefitsItems"
        :key="index"
        :item="item"
        :is-active="currentIndex === index"
        :class="{
          'benefits__card': true,
          'benefits__card--active': currentIndex === index,
        }"
        @click="handleCardClick($event)"
      />
    </div>

    <ClientOnly>
      <WsModalFreightPolitics
        :show="showFreightPolitics"
        @handle-show-modal="showFreightPolitics = false"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { slugify } from '@petlove/frontend-utilities-utils';
import Card from './Card.vue';
import Benefit from './Benefit.vue';

const { $gtm } = useNuxtApp();

const id = '5ac7bc46-23f0-43b6-8db0-fcd4b5384571';

const { isDesktop } = useDevice();

const {
  data: benefits,
  error,
  status,
} = await useLabradorFetch(`/v2/new-home/component/${id}`, {
  transform: benefits => benefits.data,
  key: 'benefits',
  lazy: true,
});

const benefitsItems = computed(() => {
  if (!benefits.value?.items) return [];

  const newItems = [...benefits.value.items];
  const index = newItems.findIndex(item => item.title === 'Clube de desconto');
  // change clube position so it can be at center
  if (index !== -1) {
    const [item] = newItems.splice(index, 1);
    newItems.splice(2, 0, item);
  }

  return newItems;
});

const currentIndex = ref(2);
const showFreightPolitics = ref(false);
const cards = ref(null);
const illustrations = ref(null);

onMounted(() => {
  scrollToIndex(currentIndex.value);
});

watch(currentIndex, (index) => {
  scrollToIndex(index);
});

const scrollToIndex = (index) => {
  const cardWidth = cards.value?.children?.[index]?.clientWidth;

  cards.value?.scrollTo({
    behavior: 'smooth',
    left: cardWidth * index + 50,
  });
  illustrations.value?.scrollTo({
    behavior: 'smooth',
    left: 150 * index,
  });
};

const openFreightPolitics = () => {
  showFreightPolitics.value = true;
};

const handleIllustrationClick = (index, title) => {
  currentIndex.value = index;

  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: `beneficios:componente:${slugify(title)}`,
    event_label: 'home',
  });
};

const handleCardClick = (item) => {
  if (item?.isTrusted) return;

  if (item) {
    const title = slugify(item.link_info);

    $gtm.dispatchEvent({
      event_category: 'click',
      event_action: `beneficios:botao:${title}`,
      event_label: 'home',
    });

    if (item?.link !== '/') {
      window.open(item.link, '_target');
    }
    else {
      openFreightPolitics();
    }
  }
};
const handleScroll = (event) => {
  const itemWidth = event.target?.children?.[currentIndex.value]?.clientWidth;
  const margin = event.target.classList.contains('benefits__illustrations')
    ? 30
    : 0;
  const itemIndex = Math.floor(event.target.scrollLeft / (itemWidth + margin));
  if (isNaN(itemIndex)) return;
  currentIndex.value = itemIndex || 0;
};

onMounted(() => {
  if (status.value === 'error') throw createError(error.value);
});
</script>

<style lang="scss">
.card__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.two-lines {
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 70px;

  &--active {
    width: 90px;
  }
}

.benefits {
  margin-bottom: space(10);
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    border-bottom-left-radius: 60% 30%;
    border-bottom-right-radius: 60% 30%;
    background-color: color(neutral, light);

    @media (min-width: $desktop-breakpoint) {
      border-bottom-left-radius: 50% 60%;
      border-bottom-right-radius: 50% 60%;
      height: 150px;
    }
  }

  &__title {
    position: relative;
  }

  .button--secondary {
    text-transform: inherit;
  }

  &__illustrations {
    width: 100%;
    height: fit-content;
    margin-bottom: space(8);
    display: flex;
    gap: space(10);
    overflow-x: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    padding: 0 50%;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: $desktop-breakpoint) {
      gap: space(14);
      justify-content: center;
      padding: 0;
      padding-top: space(6);
    }

    &__item {
      scroll-snap-align: center;
      height: fit-content;

      @media (min-width: $desktop-breakpoint) {
        &:first-child,
        &:last-child {
          transform: translateY(-20px);
        }
        &:nth-child(2),
        &:nth-child(4) {
          transform: translateY(-10px);
        }
      }
    }
  }

  &__cards {
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    min-height: 240px;
    padding: 0 50%;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__card {
    scroll-snap-align: center;
    display: flex;
    height: 342px;
    transition: transform 0.3s;
    height: 405px;
    transform: scale(0.9);
    min-width: 78vw;

    @media (min-width: $desktop-breakpoint) {
      filter: blur(1.5px);
      opacity: 0.5;
      min-width: 430px;
      height: 237px;
    }

    &--active {
      opacity: 1;
      filter: blur(0);
      transform: scale(1);

      @media (min-width: $desktop-breakpoint) {
        min-width: 430px;
      }
    }
  }
}
</style>
