<template>
  <div class="chips-action">
    <CaActionChip
      v-for="item in items"
      :key="slugify(item.title)"
      :alt="slugify(item.title)"
      name="item"
      :icon="item.icon"
      datatest-id="chips-action"
      @click="handleClick(item)"
    >
      {{ item.title }}
    </CaActionChip>
  </div>
</template>

<script setup>
import { slugify } from '@petlove/frontend-utilities-utils';

const { $gtm } = useNuxtApp();

const scrollToComponent = (componentId) => {
  const element = document.getElementById(componentId);
  if (element) {
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }
};

const items = ref([
  {
    id: 'health-insurance-plans',
    icon: 'paw_hospital',
    title: 'Plano de saúde',
  },
  {
    id: 'services-list',
    icon: 'paw_heart',
    title: 'Hospedagem',
  },
  {
    id: 'dy-recommendations-shelf-1',
    icon: 'paw_seal',
    title: 'Recomendados',
  },
  {
    id: 'shops-list',
    icon: 'marketplace',
    title: 'Lojas',
  },
  {
    id: 'dy-recommendations-shelf-2',
    icon: 'petlove_prime',
    title: 'Marcas exclusivas',
  },
  {
    id: 'benefits-bar',
    icon: 'gift',
    title: 'Benefícios',
  },
  {
    id: 'blog-posts',
    icon: 'blog2',
    title: 'Blog',
  },
]);

const handleClick = (item) => {
  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: `atalho:${slugify(item.title)}`,
    event_label: 'home',
  });

  scrollToComponent(item.id);
};

const desktopShortcutsListener = () => {
  window.onscroll = () => {
    checkIfElementIsSticked();
  };
};

const checkIfElementIsSticked = () => {
  const element = document.querySelector('.chips-action') || {};
  if (!element) return;

  const headerHeight = 85;
  const elementToTopDistance = element.getBoundingClientRect()?.top;

  if (elementToTopDistance < headerHeight)
    return element.classList?.add('sticky-chips-action');

  return element.classList?.remove('sticky-chips-action');
};

onMounted(() => {
  const { isDesktop } = useDevice();

  if (isDesktop) {
    checkIfElementIsSticked();
    desktopShortcutsListener();
  }
});
</script>

<style lang="scss" scoped>
.chips-action {
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 0.8rem;
  z-index: 2;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow: scroll;
  transition: all 0.3s ease-in-out;
  padding: space(3) 0 space(3) space(5);

  @media (min-width: $desktop-breakpoint) {
    padding-left: 0;
  }

  @media (max-width: $tablet-breakpoint) {
    &:last-child {
      padding-right: space(5);
    }
  }

  @media (min-width: $desktop-breakpoint) {
    justify-content: center;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.chips-action :deep() {
  .action-chip__label {
    width: max-content;
  }
}

.sticky-chips-action {
  position: sticky;
  top: 82px;
  background-color: white;
  box-shadow: 0px 4px 8px color(neutral, light),
    0px 0px 4px color(neutral, light);
  transition: all 0.3s ease-in-out;
}
</style>
