export const dogBreeds = {
  title: 'Raças de cachorro',
  items: [
    {
      img: 'https://www.petlove.com.br/images/breeds/192371/profile/normal/shih_tzu-p.jpg?1532539537',
      link: '/shih-tzu/r',
      name: 'Shih Tzu',
    },
    {
      img: 'https://www.petlove.com.br/images/breeds/193057/profile/mini/spitz_alemao-p.jpg?1532539555',
      link: '/spitz-alemao/r',
      name: 'Lulu da Pomerânia',
    },
    {
      img: 'https://www.petlove.com.br/images/breeds/193080/profile/normal/border_collie-p.jpg?1532538415',
      link: '/border-collie/r',
      name: 'Border Collie',
    },
    {
      img: 'https://www.petlove.com.br/images/breeds/193221/profile/normal/pitbull-p.jpg?1532539293',
      link: '/pitbull/r',
      name: 'Pit Bull',
    },
    {
      img: 'https://www.petlove.com.br/images/breeds/193223/profile/normal/golden_retriever-p.jpg?1532539102',
      link: '/golden-retriever/r',
      name: 'Golden Retriever',
    },
  ],
};
export const catBreeds = {
  title: 'Raças de gato',
  items: [
    {
      img: 'https://www.petlove.com.br/images/breeds/193045/profile/normal/maine_coon-p.jpg?1532626774',
      link: '/maine-coon/r',
      name: 'Maine Coon',
    },
    {
      img: 'https://www.petlove.com.br/images/breeds/226682/profile/normal/singapura-petlove-perfil.jpg?1596652984',
      link: '/singapura/r',
      name: 'Singapura',
    },
    {
      img: 'https://www.petlove.com.br/images/breeds/192475/profile/normal/ragdoll-p.jpg?1532626868',
      link: '/ragdoll/r',
      name: 'Ragdoll',
    },
    {
      img: 'https://www.petlove.com.br/images/breeds/192825/profile/normal/siames-p.jpg?1532626975',
      link: '/siames/r',
      name: 'Siamês',
    },
    {
      img: 'https://www.petlove.com.br/images/breeds/192473/profile/normal/persa-p.jpg?1532626845',
      link: '/persa/r',
      name: 'Persa',
    },
  ],
};
