<template>
  <div class="background">
    <div class="background__top">
      <svg
        viewBox="0 0 1366 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M336.091 0C49.0433 0 0 48 0 48L1366 48V0C1366 0 1283.78 37.5061 1005.39 37.5061C726.995 37.5061 623.138 0 336.091 0Z"
          :fill="color"
        />
      </svg>
    </div>
    <div
      class="background__content"
      :style="{ backgroundColor: color }"
    >
      <slot />
    </div>
    <div class="background__bottom">
      <svg
        viewBox="0 0 1366 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1029.91 48C1316.96 48 1366 0 1366 0L0 0V48C0 48 82.2194 10.4939 360.612 10.4939C639.005 10.4939 742.862 48 1029.91 48Z"
          :fill="color"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  svg {
    height: 100%;
    width: 100%;
  }

  &__top {
    display: flex;
    margin-bottom: -1px;
  }

  &__bottom {
    display: flex;
    margin-top: -1px;
  }
}
</style>
