<template>
  <WaveBackground
    color="#CCE9FD"
    class="my-10"
  >
    <template v-if="status === 'error'">
      <CaEmptyState
        title="Ops, lista de serviços indisponível"
        variant="horizontal"
      />
    </template>
    <div
      v-else
      class="service-list py-6"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <CaScroll
          :show-controllers="$device.isDesktop"
          :show-dots="false"
          :spacing="4"
          scroll-align="start"
        >
          <template #title>
            <CaTitle
              tag="h2"
              class="full-width text-center font-bold font-black"
              weight="black"
              color="primary"
              :size="$device.isDesktop ? 'm' : 's'"
            >
              {{ title }}
            </CaTitle>
          </template>

          <div class="service-list__cards pl-5 mt-6">
            <CaCard
              v-for="(card, index) in cards"
              :key="index"
              class="service-card"
            >
              <main class="service-card__content flex flex-column">
                <div class="service-card__content__header">
                  <div class="flex service-card__icon">
                    <CaIcon
                      v-if="card.icon"
                      :name="card.icon"
                      color="negative"
                      :size="24"
                    />

                    <span
                      class="font-body-xs color-negative font-bold p-2"
                    >
                      {{ card.service }}
                    </span>
                  </div>

                  <CaTag
                    v-if="card.tag"
                    color="positive"
                    size="small"
                    icon=""
                  >
                    {{ card.tag?.name }}
                  </CaTag>
                </div>

                <CaTitle
                  tag="h2"
                  class="service-card__content__title font-title-xs pt-4 pb-5"
                  weight="black"
                  color="primary"
                >
                  {{ card.title }}
                </CaTitle>

                <p
                  class="service-card__content__description font-body-xs color-neutral-darkest mb-10"
                >
                  {{ card.description }}
                </p>
              </main>

              <div class="service-card__content__actions flex flex-column">
                <CaLink
                  v-for="(button) in card.links"
                  :key="button.title"
                  class="service-card__content__actions__button"
                  :href="button.link"
                  full-width
                  no-padding
                >
                  <CaButton
                    :type="button.background_color"
                    :size="$device.isDesktop ? 'small' : 'x-small'"
                    full-width
                    @click="handleClick(card.service, button, $event)"
                  >
                    {{ button.title }}
                  </CaButton>
                </CaLink>
              </div>
            </CaCard>
          </div>
        </CaScroll>
      </transition>
    </div>
  </WaveBackground>
</template>

<script setup>
import { slugify } from '@petlove/frontend-utilities-utils';

const { $gtm } = useNuxtApp();

const id = '5e3da113-2966-4023-9966-bd15e39ba91c';

const {
  data: services,
  status,
  error,
} = await useLabradorFetch(`/v2/new-home/component/${id}`, {
  key: 'services',
});

const title = services?.value?.data?.title || '';
const cards = services?.value?.data?.items || [];

const handleClick = (service, button, $event) => {
  $event?.preventDefault();

  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: `componente-servicos:${slugify(service)}:${slugify(
      button.title,
    )}`,
    event_label: 'home',
  });

  window.open(button.link, '_blank');
};

onMounted(() => {
  if (status.value === 'error') throw createError(error.value);
});
</script>

<style lang="scss" scoped>
.service-list {
  @media (min-width: $desktop-breakpoint) {
    display: flex;
    justify-content: center;
  }

  .scroller {
    @media (min-width: $desktop-breakpoint) {
      position: relative;
      max-width: 1078px;
    }

    :deep() {
      .scroller__controllers {
        position: absolute;
        right: 0;
        margin-bottom: 0;
      }
    }
  }

  &__cards {
    display: flex;
    gap: space(5);

    @media (min-width: $desktop-breakpoint) {
      padding-left: 0;
    }

    &:last-child {
      @media (max-width: $tablet-breakpoint) {
        padding-right: space(5);
      }
    }

    .service-card {
      width: 200px;

      @media (min-width: $desktop-breakpoint) {
        width: 320px;
      }

      :deep() {
        .card__content {
          justify-content: space-between;
        }
      }

      &__icon {
        color: red;
        fill: red;
      }

      &__content {
        &__header {
          display: flex;
          justify-content: space-between;

          @media (min-width: $desktop-breakpoint) {
            justify-content: normal;
            gap: space(4);
          }
        }

        &__title {
          line-height: 120%;
          max-width: 240px;
        }

        &__actions {
          gap: space(3);

          &__button {
            display: block;
          }
        }
      }
    }
  }
}
</style>
