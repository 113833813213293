<template>
  <CaCard
    shadow
    no-padding
  >
    <a
      class="brand-item rounded-l"
      :href="`/marcas/${item.slug}`"
      @click="onClickLink(item.slug)"
    >
      <img
        loading="lazy"
        width="120"
        height="100"
        :alt="`Logotipo da marca ${item.name}`"
        :src="item.img"
        class="brand-item__image"
      >
    </a>
  </CaCard>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onClickLink(slugName) {
      const pathName = window && window.location?.pathname;
      this.$gtm.dispatchEvent({
        event_category: 'click',
        event_action: `marcas:card:${slugName}`,
        event_label: pathName === '/' ? 'home' : pathName,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import './style.scss';
</style>
