<template>
  <WaveBackground
    color="#74CB90"
    class="wave-position"
  >
    <template v-if="status === 'error'">
      <CaEmptyState
        title="Ops, lista de planos indisponível"
        variant="horizontal"
      />
    </template>
    <section
      v-else
      :id="id"
      class="plan"
    >
      <CaTitle
        class="plan__title title-black text-center"
        tag="h2"
        :size="$device.isDesktop ? 'm' : 's'"
        color="primary"
        datatest-id="title"
      >
        {{ title }}
      </CaTitle>
      <CaLoading v-if="isLoading" />
      <div
        v-else
        class="plan__wrapper"
      >
        <div class="mr-4">
          <CaIconButton
            v-if="$device.isDesktop && plans.length > 3"
            aria-label="Ir para o próximo plano de saúde"
            size="medium"
            icon="arrow-left"
            type="neutral"
            :disabled="plansCurrentIndex <= 0"
            @click="scrollLeft()"
          />
        </div>
        <div
          class="plan__list"
          @scroll.passive="scrollHandler"
        >
          <CaCard
            v-for="(plan, index) in plans"
            :key="plan.name"
            class="plan__card"
            datatest-id="plan"
            :padding7="index === plansCurrentIndex"
            :class="{
              opacity: index !== plansCurrentIndex,
            }"
          >
            <div class="flex-column align-items-center plan__item">
              <CaTitle
                tag="h3"
                class="title-black text-center leading-s"
                :color="shouldHighlightTitle(plan.name) ? 'accent' : 'primary'"
                size="l"
              >
                <span>{{ getTitleLine(plan.name).firstLine }}</span>
                {{ getTitleLine(plan.name).secondLine }}
              </CaTitle>

              <CaTag
                :class="[index === plansCurrentIndex ? 'mt-5' : 'mt-3']"
                :icon="plan.icon"
              >
                {{ plan.subtitle }}
              </CaTag>

              <div v-if="plan.price">
                <p
                  class="color-primary font-bold"
                  :class="[
                    index === plansCurrentIndex
                      ? 'mt-5 font-title-s'
                      : 'mt-3 font-title-xs',
                  ]"
                >
                  {{ priceFormatter(plan.price) }}/mês
                </p>

                <span class="color-primary font-body-s">
                  {{ `em ${regionDefault.name}` }}</span>
              </div>

              <div class="full-width flex-column align-items-center mt-5">
                <CaButton
                  full-width
                  :size="index === plansCurrentIndex ? 'large' : 'small'"
                  @click="goTo(plan.links[0].link, 'botao:ver-cobertura')"
                >
                  Conferir cobertura
                </CaButton>
                <CaLink
                  :href="plan.links[1].link"
                  class="mt-2"
                  datatest-id="hire"
                  @click="hirePlan(plan)"
                >
                  Contratar
                </CaLink>
              </div>
            </div>
          </CaCard>
        </div>
        <div class="ml-4">
          <CaIconButton
            v-if="$device.isDesktop && plans.length > 3"
            aria-label="Ir para o plano de saúde anterior"
            size="medium"
            icon="arrow-right"
            type="neutral"
            :disabled="plansCurrentIndex >= plans.length - 1"
            @click="scrollRight()"
          />
        </div>
      </div>
      <div
        v-if="plans.length > 1"
        class="dots"
      >
        <span
          v-for="(dot, index) in plans.length"
          :key="dot"
          class="dots__item"
          :class="{ dots__selected: index === plansCurrentIndex }"
        />
      </div>
      <div class="plan__actions full-width flex-column align-items-center">
        <CaButton
          class="mb-5"
          type="inverted"
          full-width
          size="small"
          datatest-id="first-action"
          @click="showModalSelectRegion = true"
        >
          {{ region_card.title }}
        </CaButton>
        <CaButton
          type="secondary"
          full-width
          size="small"
          datatest-id="second-action"
          @click="goTo(customer_card.link, 'botao:area-do-cliente')"
        >
          {{ customer_card.title }}
        </CaButton>
        <CaLink
          :href="network_card.link"
          class="mt-2"
          datatest-id="third-action"
          @click="goTo(network_card.link, 'link:ver-rede-credenciada')"
        >
          {{ network_card.title }}
        </CaLink>
      </div>
    </section>

    <LazyModalSelectRegion
      :regions="regions"
      :show="showModalSelectRegion"
      :is-loading="isLoading"
      @close="showModalSelectRegion = false"
      @selected-region="getRegion($event)"
    />
  </WaveBackground>
</template>

<script setup>
import { priceFormatter } from '@petlove/frontend-utilities-utils';

const { $gtm } = useNuxtApp();

const plansCurrentIndex = ref(1);
const showModalSelectRegion = ref(false);
const isLoading = ref(false);
const selectedRegion = ref(null);

const id = 'd57e4965-bf4f-4423-9cbe-49bd505a7c09';
const params = computed(() => ({
  region_id: selectedRegion?.value?.id || null,
}));

onMounted(() => {
  const index = plansCurrentIndex.value;
  const list = document?.querySelector('.plan__list');
  if (list) list.scrollLeft += index * 250;

  if (status.value === 'error') throw createError(error.value);
});

const { data, status, error } = await useLabradorFetch(
  `/v2/new-home/component/${id}`,
  {
    params,
    transform: data => data.data,
    key: 'plans',
    lazy: true,
  },
);

const plans = computed(() => data.value?.plans || []);
const title = computed(() => data.value?.title || '');
const network_card = computed(() => data.value?.network_card || {});
const customer_card = computed(() => data.value?.customer_card || {});
const region_card = computed(() => data.value?.region_card || {});
const regions = computed(() => data.value?.regions || []);

const regionDefault = computed(() => {
  const idRegionDefault = 2;
  return (
    selectedRegion?.value
    ?? data.value?.regions?.find(region => region.id === idRegionDefault)
  );
});

const getTitleLine = (name) => {
  const [firstLine, secondLine] = name.split(' ');
  return { firstLine, secondLine };
};

const goTo = (url, action) => {
  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: `componente-saude:${action}`,
    event_label: 'home',
  });
  return window.location.assign(url);
};

const hirePlan = ({ links, name }) => {
  const hire_url = links[1].link;
  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: `componente-saude:link:contratar:${name}`,
    event_label: 'home',
  });
  window.location.assign(hire_url);
};

const scrollHandler = (event) => {
  const scroll = event.target.scrollLeft;
  const cardWidth = 252;
  const index = Math.floor(scroll / cardWidth);
  if (index > plans.value?.length - 1) return;
  plansCurrentIndex.value = index;
};

const shouldHighlightTitle = (name) => {
  return name.includes('Completo') || name.includes('Essencial');
};

const getRegion = async (region) => {
  selectedRegion.value = region;
};

const scrollRight = () => {
  const list = document.querySelector('.plan__list');
  list.scrollLeft += 220;
};

const scrollLeft = () => {
  const list = document.querySelector('.plan__list');
  list.scrollLeft -= 220;
};
</script>

<style lang="scss" scoped>
.plan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: space(7);
  padding: space(6) 0;
  max-width: 840px;
  margin: 0 auto;
  min-height: 300px;

  &__title {
    width: 274px;
    line-height: 100%;

    @media (min-width: $desktop-breakpoint) {
      width: 365px;
    }
  }

  &__wrapper {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep() {
      .card {
        background-color: color(neutral, light);
        border-color: color(neutral, light);
        height: fit-content;
        transition: all 0.5s ease-out;
        align-self: center;
        justify-self: center;
        max-width: 276px;
      }

      .link {
        padding-bottom: 0;
      }
    }
  }

  &__list {
    width: 100%;
    display: flex;
    gap: space(6);
    overflow-x: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    padding: 0 50%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__card {
    scroll-snap-align: center;
    transition: all 0.5s ease-out;
  }

  &__item {
    min-width: 220px;
    transition: all 0.5s ease-out;

    h3 > span {
      display: block;
      font-size: fontSize(body);
    }

    :deep() {
      .tag {
        background-color: color(neutral, lightest);
      }
    }
  }

  &__actions {
    max-width: 365px;
    padding: 0 space(5);
  }
}

.dots {
  display: flex;
  gap: space(4);

  &__item {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: color(neutral, light);
    transition: background-color 0.3s;
  }
  &__selected {
    background-color: color(primary);
  }
}

.opacity {
  opacity: 0.8;
}

.wave-position {
  @media (min-width: $desktop-breakpoint) {
    margin-bottom: -48px;
  }
}
</style>
