<template>
  <div
    :class="{
      'bg-neutral-fluid': neutralBg,
    }"
  >
    <div
      class="recommendations"
      :class="{
        'recommendations__large-padding': neutralBg,
      }"
    >
      <WsDyRecommendationsShelf
        class="recommendations-shelf"
        buy-option
        :loading="isAddingToCart"
        :experiment="experiment"
        :page-type="pageType"
        :is-club-ab-test-active="isClubAbTestActive"
        :list-name="listName"
        @add-to-cart="handleRecommendationsAddToCart"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';

export default {
  props: {
    experiment: {
      type: String,
      required: true,
    },
    neutralBg: {
      type: Boolean,
      default: false,
    },
    pageType: {
      type: String,
      default: 'HOMEPAGE',
    },
    isClubAbTestActive: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAddingToCart: false,
      background: {
        type: 'fluid',
        color: 'neutral-bg',
      },
    };
  },
  methods: {
    ...mapActions(useCartStore, ['addVariantToCart', 'upsertCart']),
    ...mapActions(useNotificationsStore, ['setSnackbar']),
    async handleRecommendationsAddToCart({ sku, quantity = 1 }) {
      this.isAddingToCart = true;

      try {
        await this.addVariantToCart({
          variants: [{ sku, quantity }],
          subscription: {},
        });

        await this.upsertCart();

        this.setSnackbar({
          show: true,
          message: 'Produto adicionado ao carrinho',
          type: 'positive',
        });
      }
      catch (error) {
        this.$honeybadger?.notify(error);
        this.setSnackbar({
          show: true,
          message: 'Erro ao adicionar o produto no carrinho',
          type: 'negative',
        });
      }
      finally {
        this.isAddingToCart = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-neutral-fluid {
  background: color(neutral, light);
  margin-top: -10px;

  &__custom_spaces {
    @media (min-width: $desktop-breakpoint) {
      margin-top: 14px;
    }

    @media (min-width: $large-desktop-breakpoint) {
      margin-top: -5px;
    }

    @media (min-width: $extra-large-desktop-breakpoint) {
      margin-top: 0;
    }
  }
}

.recommendations {
  max-width: 970px;
  margin: 0 auto;

  @media (min-width: $desktop-breakpoint) {
    padding: space(10) 0;
  }

  &__large-padding {
    padding: space(10) 0;
  }
}
</style>
