export const loadDYScripts = (dyEnvId: string) => {
  const scripts = [
    `https://cdn.dynamicyield.com/api/${dyEnvId}/api_dynamic.js`,
    `https://cdn.dynamicyield.com/api/${dyEnvId}/api_static.js`,
  ];

  scripts.forEach((src) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  });
};

export const initDY = () => {
  window.dataLayer = window.dataLayer || [];
  window.DY = window.DY || {};
  window.DY.recommendationContext = { type: 'HOMEPAGE' };
};
