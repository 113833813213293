<template>
  <div class="flex flex-column align-items-center">
    <div
      class="benefit"
      :class="{ 'benefit--active': isActive }"
      @click="$emit('click', item.card)"
    >
      <component
        :is="item.illustration"
        filled
        height="72"
        width="72"
        :class="
          isActive ? 'benefit__ilustration--active' : 'benefit__ilustration'
        "
      />
    </div>
    <CaTitle
      tag="h2"
      :class="{
        'text-center font-body-xs two-lines benefit__title': true,
        'font-body two-lines--active benefit__title--active': isActive }"
      weight="black"
      :color="isActive ? 'primary' : 'neutral-darkest'"
    >
      {{ item.title }}
    </CaTitle>
  </div>
</template>

<script>
import box_toys_yellow from '@petlove/caramelo-draws/src/box_toys_yellow.svg';
import icon_discount from '@petlove/caramelo-draws/src/icon_discount.svg';
import picture_frame from '@petlove/caramelo-draws/src/picture_frame.svg';
import bag from '@petlove/caramelo-draws/src/bag.svg';
import icon_house from '@petlove/caramelo-draws/src/icon_house.svg';

export default {
  components: {
    box_toys_yellow,
    icon_discount,
    picture_frame,
    bag,
    icon_house,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
};
</script>

<style lang="scss" scoped>
.benefit {
  background-color: color(white);
  border-radius: rounded(circle);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: space(4);
  box-shadow: shadow(m);
  transition: all 0.3s ease-in-out;
  padding: 8px;
  cursor: pointer;
  width: 96px;
  height: 96px;
  box-sizing: border-box;
  transform: scale(0.6);
  transition: all 0.3s ease-in-out;

  &__title {
    line-height: 125%;
    filter: opacity(0.5);

    &--active {
      filter: opacity(1);
    }
  }

  &--active {
    border: 4px solid color(primary);
    transform: scale(1);
  }

  &__ilustration {
    width: 72px;
    height: 72px;
    opacity: 0.5;
    fill: none;

    &--active {
      width: 72px;
      height: 72px;
      fill: none;
    }
  }
}
</style>
