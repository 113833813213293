<template>
  <div
    id="featured-categories"
    class="categories"
  >
    <div :class="['categories__wrapper', !$device.isDesktop && 'full-width']">
      <CaTitle
        tag="h2"
        size="s"
        :class="['font-bold title-black', !$device.isDesktop && 'text-center']"
        color="primary"
        datatest-id="title"
      >
        {{ data.title }}
      </CaTitle>
      <ul
        class="categories__list"
        datatest-id="list"
      >
        <li
          v-for="category in data.items"
          :key="category.text"
          datatest-id="category"
          @click="redirect(category, $event)"
        >
          <a
            :href="category.path"
            title="Ir para Rações"
            class="flex"
          >
            <div class="flex-column align-items-center px-2">
              <Component
                :is="getIllustrations(category.illustration)"
                v-if="category.illustration"
                datatest-id="image"
                filled
                :font-controlled="false"
                height="95"
                width="106"
              />
              <img
                v-else
                loading="lazy"
                class="categories__list__image"
                :src="category.image"
                :alt="category.text"
              >
              <div
                class="categories__list__text font-medium color-primary mt-3 text-center"
                datatest-id="text"
              >
                {{ category.text }}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
const { $gtm } = useNuxtApp();

const { data } = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const getIllustrations = (illustration) => {
  const illustrations = {
    food: defineAsyncComponent(() => import('@petlove/caramelo-draws/src/food.svg')),
    hygiene: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/hygiene_and_cleaning.svg'),
    ),
    medicine: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/medicine_and_health.svg'),
    ),
    foodAccessories: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/food_accessories.svg'),
    ),
    dryFood: defineAsyncComponent(() => import('@petlove/caramelo-draws/src/dry_food.svg')),
    antiFlea: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/anti_flea.svg'),
    ),
    sand: defineAsyncComponent(() => import('@petlove/caramelo-draws/src/sand.svg')),
    medicinalFood: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/medicinal_food.svg'),
    ),
    wetFood: defineAsyncComponent(() => import('@petlove/caramelo-draws/src/wet_food.svg')),
    hygienicCarpet: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/hygienic_carpet.svg'),
    ),
    toys: defineAsyncComponent(() => import('@petlove/caramelo-draws/src/toys.svg')),
    snacks: defineAsyncComponent(() => import('@petlove/caramelo-draws/src/snacks.svg')),
    suplements: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/suplements.svg'),
    ),
  };
  return illustrations[illustration];
};

const redirect = (category) => {
  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: `categoria:${category.text}`,
    event_label: 'home',
  });

  return window.location.assign(category.path);
};
</script>

<style scoped lang="scss">
.categories {
  display: flex;
  justify-content: center;

  @media (max-width: $tablet-breakpoint) {
    padding-left: space(5);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: space(7);
  }

  &__list {
    display: flex;
    justify-content: space-evenly;

    overflow-x: auto;
    padding-right: 30px;
    scrollbar-width: none;
    gap: space(6);

    @media (max-width: $desktop-breakpoint) {
      justify-content: flex-start;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & > * {
      width: 105px;
    }

    &__image {
      mix-blend-mode: multiply;
    }

    &__text {
      width: 105px;
    }
  }
}
svg {
  height: unset;
}
</style>
