<template>
  <div class="next-best-action">
    <ClientOnly>
      <CaLoading
        v-if="status === 'pending'"
        class="next-best-action__loading"
      />

      <CaCard
        v-for="(card, index) in cards"
        :key="index"
        outlined
        class="next-best-action__card slide-in-and-overshoot"
        :bg-primary-lightest="card.background_color === 'primary'"
        @click="handleClick(card)"
      >
        <div class="row align-items-center">
          <div class="col flex align-items-center">
            <div>
              <component
                :is="getIllustration(card.illustration)"
                filled
                class="next-best-action__card__ilustration"
              />
            </div>

            <div>
              <p
                class="next-best-action__card__title font-medium text-left font-body color-primary"
              >
                {{ card.title }}
              </p>

              <p
                class="next-best-action__card__description font-body-s color-neutral-dark text-left"
              >
                {{ card.description }}
              </p>

              <div
                v-if="card.items && card.items.length"
                class="mt-4 ml-5"
              >
                <CaThumbnailGroup class="thumbnail-group">
                  <template v-for="(item, i) in card.items">
                    <CaBadge
                      v-if="i < 5"
                      :key="i"
                      datatest-id="badge-quantity"
                      :value="item.quantity > 1 ? item.quantity : null"
                      color="primary"
                      position="bottom"
                    >
                      <CaThumbnail
                        datatest-id="thumbnail"
                        :src="item.img"
                        :size="48"
                        border
                      />
                    </CaBadge>
                  </template>
                </CaThumbnailGroup>
              </div>
            </div>
          </div>

          <CaIconButton
            icon="cursor-hand"
            type="inverted"
            size="small"
            aria-label="visualizar"
          />
        </div>
      </CaCard>
      <template #fallback>
        <CaLoading class="next-best-action__loading" />
      </template>
    </ClientOnly>
  </div>
</template>

<script setup>
import { getCookie } from '@petlove/lion/libs/cookies';
import { slugify } from '@petlove/frontend-utilities-utils';

const { $gtm } = useNuxtApp();

const getIllustration = (illustration) => {
  const illustrations = {
    box_toys_yellow: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/box_toys_yellow.svg'),
    ),
    icon_health: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/icon_health.svg'),
    ),
    icon_thumbs_up: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/icon_thumbs_up.svg'),
    ),
    dog_waiting: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/dog_waiting.svg'),
    ),
    bag: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/bag.svg'),
    ),
    icon_calendar: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/icon_calendar.svg'),
    ),
    alert: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/alert.svg'),
    ),
    review: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/review.svg'),
    ),
    icon_discount: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/icon_discount.svg'),
    ),
    photo_cell: defineAsyncComponent(() =>
      import('@petlove/caramelo-draws/src/photo_cell.svg'),
    ),
  };
  return illustrations[illustration];
};

const orderNumber = ref(getCookie('PL_orderNumber') || null);

const { getSession } = useAuthStore();

const id = 'b8132541-d2ac-49c9-bb47-aab43be94719';
const params = {
  public_id: getSession?.public_id || '',
  current_cart_number: orderNumber.value || null,
  subscriber: getSession?.subscriber || false,
};

const { data, status } = await useLabradorFetch(
  `/v2/new-home/component/${id}`,
  {
    lazy: true,
    server: false,
    params,
    transform: data => data.data,
  },
);

const cards = computed(() => data.value?.cards || []);

const handleClick = (card) => {
  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: `nba:${slugify(card.title)}`,
    event_label: 'home',
  });
  window.open(card.link, '_self');
};
</script>

<style lang="scss" scoped>
.next-best-action {
  display: flex;
  gap: space(3);
  overflow: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  max-width: 1050px;
  margin: 0 auto;
  padding-top: space(5);
  padding-bottom: space(3);
  min-height: 140px;

  @media (max-width: $tablet-breakpoint) {
    padding: space(5);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__loading {
    margin: 0 auto;
  }

  &__card {
    border: 1px solid color(primary, lightest);
    display: flex !important;
    align-items: center !important;
    min-width: 330px;
    max-height: 108px;
    cursor: pointer;

    :deep() {
      .card__content {
        width: 100%;
      }
    }

    @media (min-width: $tablet-breakpoint) {
      width: 33%;
    }

    &__ilustration {
      width: 42px;
      height: 42px;
      margin-right: space(3);
      fill: none;
    }

    &__title {
      line-height: 125%;
    }

    &__description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 100%;
      word-break: break-word;
      line-height: 125%;
    }
  }
}

.slide-in-and-overshoot {
  transform: translateX(110%);
  animation: slideInAndOvershoot 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
}

.thumbnail-group :deep(.thumbnail-group__content *) {
  margin-left: -5px;
}

@keyframes slideInAndOvershoot {
  0% {
    transform: translateX(110%);
  }

  70% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
