export const dogCategories = {
  title: 'Destaques para cachorro',
  items: [
    {
      text: 'Rações',
      illustration: 'food',
      name: 'racoes',
      path: '/cachorro/racoes',
    },
    {
      text: 'Higiene e limpeza',
      illustration: 'hygiene',
      name: 'higiene-e-limpeza',
      path: '/cachorro/higiene-e-limpeza',
    },
    {
      text: 'Medicina e saúde',
      illustration: 'medicine',
      name: 'medicina-e-saude',
      path: '/cachorro/medicina-e-saude',
    },
    {
      text: 'Acessórios de alimentação',
      illustration: 'foodAccessories',
      name: 'acessorios-para-alimentacao',
      path: '/cachorro/acessorios-para-alimentacao',
    },
  ],
};

export const catCategories = {
  title: 'Destaques para gato',
  items: [
    {
      text: 'Rações',
      illustration: 'food',
      name: 'racoes',
      path: '/gatos/racoes',
    },
    {
      text: 'Caixa de areia e limpeza',
      illustration: 'hygiene',
      name: 'caixa-de-areia-e-limpeza',
      path: '/gatos/caixa-de-areia-e-limpeza',
    },
    {
      text: 'Medicina e saúde',
      illustration: 'medicine',
      name: 'medicina-e-saude',
      path: '/gatos/medicina-e-saude',
    },
    {
      text: 'Acessórios de alimentação',
      illustration: 'foodAccessories',
      name: 'acessorios-para-alimentacao',
      path: '/gatos/acessorios-para-alimentacao',
    },
  ],
};
