<script setup>
import {
  title,
  description,
  canonical,
  metatags,
  link_icons,
  structuredData,
  schemaCorporation,
  manifest,
  schemaOrganizations,
} from '@petlove/lion/libs/metatags';
import { useUiStore } from '@petlove/lion/store/ui';
import { useNotificationsStore } from '@petlove/lion/store/notifications';

import { dogCategories, catCategories } from '~/data/categories';
import { dogBreeds, catBreeds } from '~/data/breeds';

const { isDesktop } = useDevice();
const { setWebView } = useUiStore();
const { query: { webview }, path } = useRoute();
const layout = isDesktop ? 'desktop' : 'mobile';
const notificationsStore = useNotificationsStore();
const { snackbar } = storeToRefs(notificationsStore);
const firstDyCampaignName = isDesktop ? 'HOME DESKTOP - RECOMMENDED PRODUCTS' : 'HOME MOBILE - RECOMMENDED PRODUCTS';
const secondDyCampaignName = isDesktop ? 'HOME DESKTOP - EXCLUSIVE BRANDS' : 'HOME MOBILE - EXCLUSIVE BRANDS';

if (path !== '/') {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page not found',
    message: 'Rota desconhecida na homepage',
  });
}

if (webview) {
  setWebView(true);
}

const linkIcons = await link_icons({});
const {
  public: { baseURL, dyEnvId },
} = useRuntimeConfig();

useHead({
  title: title(),
  description: description(),
  meta: [...metatags()],
  link: [
    canonical(),
    manifest,
    ...linkIcons,
    { rel: 'preconnect', href: baseURL },
    { rel: 'preconnect', href: 'https://dy-api.com' },
  ],
  script: [
    { type: 'application/ld+json', innerHTML: structuredData },
    { type: 'application/ld+json', innerHTML: schemaCorporation },
    {
      type: 'application/ld+json',
      innerHTML: schemaOrganizations,
    },
  ],
});

function closeSnackbar() {
  notificationsStore.setSnackbar({
    type: '',
    show: false,
    message: '',
  });
}

onMounted(() => {
  initDY();
  loadDYScripts(dyEnvId);
  getClubAbTest();
});

// TODO: club ab test. Remove this block after the test is finished
const isClubAbTestActive = ref(false);
async function getClubAbTest() {
  const { query: { recommendationsTest } } = useRoute();

  if (recommendationsTest) {
    isClubAbTestActive.value = true;
    return;
  }
  const { isLogged } = useAuthStore();
  if (!isLogged) return;

  const experiment = isDesktop ? 'club-at-recommendations-ab-test-desktop' : 'club-at-recommendations-ab-test-mobile';
  const payload = {
    experiment,
    pageType: 'HOMEPAGE',
  };

  try {
    const { getDYChoose } = useDynamicYield();
    const data = await getDYChoose(payload);
    isClubAbTestActive.value = !!data?.choices?.[0]?.variations?.[0]?.payload?.data;
    const analyticsData = data?.choices?.[0]?.variations?.[0]?.analyticsMetadata;
    if (analyticsData) {
      window?.dataLayer?.push({
        event: 'DY Variation Impression',
        campaignID: analyticsData.campaignId,
        campaignName: analyticsData.campaignName,
        experienceID: analyticsData.experienceId,
        experienceName: analyticsData.experienceName,
        variationID: analyticsData.variationId,
        variationName: analyticsData.variationName,
      });
    }
  }
  catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <NuxtLayout
    :name="layout"
  >
    <CaSnackbar
      v-bind="snackbar"
      @close="closeSnackbar"
    />

    <div
      class="home"
    >
      <NextBestAction id="next-best-action" />
      <ChipsAction class="shortcuts" />
      <BannerOffersPrimary id="banner-offers-primary" />
      <div
        v-if="catCategories || dogCategories"
        :class="[isDesktop ? 'flex' : 'flex-column']"
        class="justify-content-center gap-5 my-8"
      >
        <CategoriesList
          id="cat-categories"
          :data="catCategories"
        />
        <CategoriesList
          id="dog-categories"
          :data="dogCategories"
        />
      </div>
      <HealthInsurancePlans id="health-insurance-plans" />
      <BannerOffersSecondary id="banner-offers-secondary" />
      <DyRecommendationsShelf
        id="dy-recommendations-shelf-1"
        :experiment="firstDyCampaignName"
        neutral-bg
        :is-club-ab-test-active="isClubAbTestActive"
        list-name="home | vitrine 1 | recomendacoes"
      />
      <BenefitsBar id="benefits-bar" />
      <DyRecommendationsShelf
        id="dy-recommendations-shelf-2"
        :experiment="secondDyCampaignName"
        :is-club-ab-test-active="isClubAbTestActive"
        list-name="home | vitrine 2 | marcas exclusivas"
      />
      <Banner class="px-5" />
      <ServicesList id="services-list" />
      <BrandsList id="brands-list" />
      <BlogPosts id="blog-posts" />
      <ShopsList id="shops-list" />
      <NewsletterForm id="newsletter-form" />
      <div
        v-if="catBreeds && dogBreeds"
        :class="[isDesktop ? 'flex' : 'flex-column']"
        class="justify-content-center gap-5 mb-8"
      >
        <BreedsList
          id="dog-breeds"
          :data="dogBreeds"
        />
        <BreedsList
          id="cat-breeds"
          :data="catBreeds"
        />
      </div>
      <PageTitle />
    </div>
  </NuxtLayout>
</template>

<style scoped>
.home {
  position: relative;
}
</style>
