<template>
  <div class="newsletter py-8 my-10">
    <!-- Step 1 -->

    <div
      v-if="!data.submitCompleted"
      class="newsletter__wrapper"
      :class="{ container: !isDesktop }"
    >
      <CaTitle
        tag="h3"
        :size="isDesktop ? 's' : 'xs'"
        class="mb-5 text-center"
        weight="black"
        color="white"
      >
        Cadastre-se e fique sabendo das ofertas antes de todos!
      </CaTitle>
      <div class="row align-items-center">
        <div class="col-12 col-md-5">
          <CaInputText
            id="input"
            v-model="data.newsletter.email"
            type="email"
            label="seu@email.com"
            required
          />
        </div>

        <div class="col-12 col-md-4">
          <CaSelect
            id="select"
            v-model="data.pet"
            label="Qual seu pet?"
            @change="(event) => (data.pet = event)"
          >
            <option
              disabled
              value=""
            >
              qual seu pet?
            </option>
            <option value="cachorro">
              cachorro
            </option>
            <option value="gato">
              gato
            </option>
            <option value="passaro">
              pássaro
            </option>
            <option value="roedor">
              roedor
            </option>
            <option value="peixe">
              peixe
            </option>
            <option value="reptil">
              réptil
            </option>
          </CaSelect>
        </div>

        <div class="col-12 col-md-3">
          <CaButton
            class="mb-6"
            type="inverted"
            full-width
            :size="getSize"
            :disabled="!isValidEmail || !data.pet"
            datatest-id="newsletter-submit"
            @click="onClickSubmitNewsletter('newsletter')"
          >
            {{ data.submitButton.text }}
          </CaButton>
        </div>
        <div
          v-if="data.hasError"
          class="mt-5 mb-8 color-white font-bold text-center font-body"
        >
          OPS! Ocorreu um erro :(
        </div>
      </div>
    </div>

    <!-- Step 2 -->
    <div v-if="data.submitCompleted">
      <div
        v-if="isDesktop"
        class="font-bold font-18 text-center my-10 color-white"
      >
        Cadastrado ;) Você será avisado sobre nossas ofertas em primeira mão!
      </div>
      <div v-else>
        <div class="font-bold font-18 color-white pt-4 pb-12">
          Cadastrado! Nunca comprou aqui? Experimente com 15% OFF
        </div>

        <div
          class="dashed bg-white p-4 mb-5 font-medium text-center color-primary"
        >
          GANHE15
        </div>

        <div class="color-white font-body">
          Desconto não cumulativo e válido para a 1ª compra recorrente.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { validateEmail } from '@petlove/frontend-utilities-validations';
import { setCookie, COOKIE_EMAIL } from '@petlove/lion/libs/cookies';

const { $gtm } = useNuxtApp();

const { isMobile } = useDevice();

const data = reactive({
  submitCompleted: false,
  submitButton: {
    text: 'cadastrar',
    default: 'cadastrar',
    loading: 'enviando...',
  },
  newsletter: {
    email: '',
    option: true,
  },
  pet: '',
  isLoading: false,
  addCoupon: false,
  hasError: false,
});

const device = computed(() => (isMobile ? 'mobile' : 'desktop'));
const isDesktop = computed(() => device.value === 'desktop');
const getSize = computed(() => (isMobile.value ? 'large' : ''));
const isValidEmail = computed(() => validateEmail(data.newsletter.email));

const submitNewsletter = async (newsletter, source = '') => {
  const payload = {
    ...newsletter,
    source,
    pet: [data.pet],
    page: window.location.pathname,
    subchannel: device.value,
  };
  const { status } = await addNewsletter(payload);

  const statusSuccess = status.value === 'success';

  if (statusSuccess) {
    onSuccessSubmitNewsletter();
  }
  else {
    onErrorSubmitNewsletter();
  }
  submitNewsletterEvent(statusSuccess);
};

const submitNewsletterEvent = (statusSuccess) => {
  const pathName = window.location?.pathname;
  $gtm.dispatchEvent({
    event_category: 'newsletter',
    event_action: statusSuccess,
    event_label: pathName === '/' ? 'home' : pathName,
  });
};

const onClickSubmitNewsletter = (source = '') => {
  setLoading();
  submitNewsletter(data.newsletter, source);
};

const setLoading = () => {
  data.isLoading = true;
  data.submitButton.text = data.submitButton.loading;
};

const onSuccessSubmitNewsletter = () => (data.submitCompleted = true);

const onErrorSubmitNewsletter = () => {
  data.submitButton.text = data.submitButton.default;
  data.hasError = true;
};

const addNewsletter = (data) => {
  const pets = data?.pet.map((pet) => {
    return { species: pet };
  });

  setUserEmailInCookie(data.email);

  const lead = {
    email: data.email,
    optin: data.option,
    pet: pets,
    page: data.page,
    source: data.source,
    channel: 'website',
    subchannel: data.subchannel,
  };

  return useLabradorFetch('/v1/newsletter', {
    method: 'POST',
    body: lead,
  });
};

const setUserEmailInCookie = (email) => {
  setCookie(COOKIE_EMAIL, email);
};
</script>

<style scoped lang="scss">
.newsletter {
  background-color: color(primary, dark);
  button {
    margin-top: 0;
    top: 0;
  }

  &__wrapper {
    max-width: 1078px;
    margin: 0 auto;
  }
  .dashed {
    border: 2px dashed color(primary);
  }
}
</style>
