<template>
  <div class="default-carousel">
    <a
      v-if="controls"
      class="default-carousel__controls--previous"
    >
      <CaIconButton
        icon="arrow-left"
        type="secondary"
        size="large"
        aria-label="Anterior"
        :disabled="page === 1"
        @click="previous()"
      />
    </a>
    <!-- carousel slider -->
    <div
      ref="slider"
      class="default-carousel__slider p-2"
    >
      <div
        ref="sliderContent"
        class="flex full-width"
        :class="{ 'justify-content-center': center }"
      >
        <slot />
      </div>
    </div>
    <a
      v-if="controls"
      class="default-carousel__controls--next"
    >
      <CaIconButton
        icon="arrow-right"
        type="secondary"
        size="large"
        aria-label="Próximo"
        :disabled="totalPages === page"
        @click="next()"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    controls: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: false,
    },
    countItems: {
      type: Number,
      default: 0,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 5000,
    },
  },
  emits: ['change'],
  data() {
    return {
      perPage: 4,
      page: 1,
      intervalId: null,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.countItems / this.perPage);
    },
  },
  mounted() {
    if (this.infinite) {
      this.setupInterval();
    }
  },
  beforeUnmount() {
    if (this.infinite) {
      this.clearInterval();
    }
  },
  methods: {
    async moveToPage(page) {
      const { offsetWidth } = this.$refs.slider || {};

      if (!offsetWidth) {
        return;
      }

      if (page <= 0) {
        page = this.totalPages;
      }
      else if (page > this.totalPages) {
        page = 1;
      }

      await this.$refs.slider.scroll({
        left: offsetWidth * (page - 1),
        behavior: 'smooth',
      });

      this.page = page;

      this.$emit('change', this.page);
    },

    async previous() {
      await this.moveToPage(this.page - 1);
    },

    async next() {
      await this.moveToPage(this.page + 1);
    },

    setupInterval() {
      this.setupInterval = setInterval(() => {
        this.next();
      }, this.interval);
    },
    clearInterval() {
      clearInterval(this.intervalId);
    },
  },
};
</script>

<style scoped lang="scss">
@import './style.scss';
</style>
