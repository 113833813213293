import { setUtms } from '@petlove/lion/libs/cookies';

export default defineNuxtPlugin({
  name: 'set-utms',
  parallel: true,
  setup() {
    const queryParams = useRoute().query;
    setUtms(queryParams);
  },
});
