<template>
  <template v-if="status === 'error'">
    <CaEmptyState
      title="Ops, lista de lojas indisponível"
      variant="horizontal"
    />
  </template>
  <div
    v-else
    class="shop"
  >
    <CaScroll
      :spacing="5"
      :show-dots="false"
      :show-controllers="$device.isDesktop"
    >
      <template #title>
        <CaTitle
          tag="h2"
          class="full-width text-center font-bold font-black"
          weight="black"
          color="primary"
          :size="$device.isDesktop ? 'm' : 's'"
        >
          {{ component?.data?.title }}
        </CaTitle>
      </template>
      <div
        v-for="shop in component?.data?.items"
        :key="shop.name"
        class="shops-list__item pl-5"
      >
        <CaCard
          no-padding
          class="shops-list__card"
        >
          <CaTag
            class="shops-list__item__tag"
            size="small"
          >
            {{
              shop.type
            }}
          </CaTag>
          <img
            loading="lazy"
            :src="shop.img"
            :alt="shop.name"
            class="shops-list__item__image"
            width="203"
            height="180"
          >
          <div class="my-4 mx-7">
            <CaTitle
              tag="h3"
              size="xs"
              class="title-black mb-1"
              color="primary"
            >
              <component
                :is="shop.title_link ? 'StoreLink' : 'span'"
                :title_link="shop.title_link"
              >
                {{ shop.name }}
              </component>
            </CaTitle>

            <p class="shops-list__item__description">
              {{ shop.info }}
            </p>
          </div>
          <div class="mt-1 ml-7 shops-list__item__cta">
            <CaLink
              no-padding
              icon-placement="right"
              icon="chevron-right"
              color="primary"
              :href="shop.link"
              @click="handleShopClick(shop, $event)"
            >
              {{ shop.type === 'loja' ? 'Entrar em contato' : 'Como chegar' }}
            </CaLink>
          </div>
        </CaCard>
      </div>
    </CaScroll>
    <footer class="flex justify-content-center px-5">
      <CaButton
        type="secondary"
        size="small"
        class="shops-list__button"
        :full-width="$device.isMobile"
        @click="goToShopsPage()"
      >
        Saiba mais
      </CaButton>
    </footer>
  </div>
</template>

<script setup>
const { $gtm } = useNuxtApp();

const id = 'f9519cc7-6cde-41f8-b187-ddcf6e493d94';
const {
  data: component,
  error,
  status,
} = await useLabradorFetch(`/v2/new-home/component/${id}`, {
  key: 'shops',
  lazy: true,
});

const handleShopClick = (shop, $event) => {
  $event?.preventDefault();

  const schedule_service = 'componente-lojas-fisicas:link:agendar-servico';
  const how_to_get_there = 'componente-lojas-fisicas:link:como-chegar';

  const eventAction = shop.type === 'loja' ? schedule_service : how_to_get_there;

  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: eventAction,
    event_label: 'home',
  });

  if (shop.type === 'loja' && shop.phone) {
    const phoneFormatted = shop.phone.replace(/\D/g, '');
    window.open(
      `https://api.whatsapp.com/send?phone=55${phoneFormatted}&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20um%20servi%C3%A7o%21`,
      '_blank',
    );
  }
  else {
    window.open(shop.link, '_blank');
  }
};

const goToShopsPage = () => {
  $gtm.dispatchEvent({
    event_category: 'click',
    event_action: 'componente-lojas-fisicas:botao:saber-mais',
    event_label: 'home',
  });

  window.open('/lojas-fisicas', '_blank');
};

onMounted(() => {
  if (status.value === 'error') throw createError(error.value);
});
</script>

<style lang="scss" scoped>
.scroller {
  @media (min-width: $desktop-breakpoint) {
    position: relative;
    max-width: 1078px;
  }

  :deep() {
    .scroller__controllers {
      position: absolute;
      right: 0;
      margin-bottom: 0;
    }
  }
}

.shop {
  max-width: 1078px;
  margin: space(10) auto;
}
.shops-list {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: scroll;
  gap: space(5);
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
  &__item {
    margin-bottom: space(7);
    position: relative;
    max-width: 203px;
    scroll-snap-align: center;

    :deep() {
      .card {
        border: none;
      }
    }
    &__image {
      width: 100%;
      border-radius: rounded(l) rounded(l) 0 0;
      object-fit: cover;
    }
    &__tag {
      position: absolute;
      right: space(3);
      top: space(3);
      text-transform: capitalize;
    }
    &__description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      color: color(neutral, dark);
      font-size: fontSize(body-s);
      line-height: 125%;
    }

    &__cta {
      bottom: space(7);
      position: absolute;
    }
  }
  &__card {
    height: 380px;
    width: 203px;
  }
  &__button {
    @media (min-width: $tablet-breakpoint) {
      width: 30%;
    }
  }
}
</style>
