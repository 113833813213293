<template>
  <CaCard
    class="card-image blog-card"
    :class="`blog-card-${$device.isDesktop ? 'desktop' : 'mobile'}`"
    outline
    shadow
    no-pading
    @click="$emit('click')"
  >
    <div class="blog-card__wrap-image">
      <img
        ref="image"
        loading="lazy"
        data-sizes="auto"
        class="lazyloadBlog blog-card__image"
        :src="image"
        :alt="alt"
        width="175"
        height="130"
      >
    </div>
    <div class="blog-card__content">
      {{ title }}
    </div>
    <template #footer>
      <CaLink
        class="blog-card__footer"
        color="primary"
        icon-placement="right"
        icon="chevron-right"
        target="_blank"
        :no-padding="true"
        full-width
        :href="link"
        :disabled="disabled"
        @click="$emit('click-link')"
      >
        Ler mais
      </CaLink>
    </template>
  </CaCard>
</template>

<script>
export default {
  name: 'BlogCard',
  props: {
    image: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'click-link'],
  data() {
    return {
      // TODO: substituír pelo link da imagem fallback quando estiver no blobfish
      fallbackSrc: 'https://i.imgbun.com/YFDk.png',
      initialSrc:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=',
    };
  },
  methods: {
    setFallbackImage(img) {
      img.setAttribute('src', this.fallbackSrc);
    },
    onLoaded(img) {
      const { naturalWidth, naturalHeight } = img;
      const minRatio = 1;
      const maxRatio = 2.5;
      const naturalRatio = naturalWidth / naturalHeight;
      if (naturalRatio < minRatio || naturalRatio > maxRatio) {
        this.setFallbackImage(img);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.blog .blog-card {
  overflow: hidden;
  border: none;
  padding-bottom: 5.6rem;
  &-desktop {
    width: 21rem;
  }
  &-mobile {
    width: 46.7vw;
  }
  &__wrap-image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 74%;
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -5px;
    left: 0;
    object-fit: cover;
  }
  &__content {
    margin: calc(space(7) - 5px) space(5) 0 space(5);
    font-weight: fontWeight(medium);
    font-size: fontSize(body);
    overflow: hidden;
    max-height: 5em;
    line-height: 1.25em;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  :deep() {
    .card {
      border: none;
      &__content {
        padding: 0;
        justify-content: inherit;
      }
      &__footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: space(5);
        &:before {
          display: none;
        }
      }
    }
  }
}
</style>
