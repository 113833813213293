<template>
  <div class="blog-filter">
    <Carousel
      :controls="false"
      class="blog-filter__carousel"
      :center="!!$device.isDesktop"
    >
      <CaChipGroup
        class="blog-filter__chips"
        scroll
        center
      >
        <CaChip
          v-for="category in categories"
          :id="`category-${category.slug}`"
          :key="category.slug"
          v-model="selectedCategory"
          :value="category.slug"
          :checked="modelValue === category.slug"
          :size="$device.isDesktop ? 'default' : 'small'"
          type="radio"
          class="blog-filter__chip"
          @input="setValue"
        >
          <span>{{ category.title }}</span>
        </CaChip>
      </CaChipGroup>
    </Carousel>
  </div>
</template>

<script>
export default {
  name: 'BlogFilter',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'update:modelValue'],
  data() {
    return {
      selectedCategory: '',
    };
  },
  methods: {
    setValue(value) {
      this.$emit('input', value);
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style scoped lang="scss">
.blog-filter {
  &__chips {
    justify-content: center;
    @media (max-width: $tablet-breakpoint) {
      justify-content: flex-start;
      margin-bottom: space(8);
      padding: 0 space(5);
    }
  }
}
</style>
