<template>
  <div
    class="bg-neutral-fluid__custom_spaces"
    :class="{
      'bg-neutral-fluid': true,
    }"
  >
    <div class="brands-default-carousel">
      <CaIconButton
        v-if="$device.isDesktop"
        class="mr-4"
        icon="arrow-left"
        size="small"
        aria-label="voltar"
        :disabled="scrollLeftDisabled"
        @click="scroll('left')"
      />

      <!-- carousel slider -->
      <div
        ref="slider"
        class="brands-default-carousel__slider"
      >
        <ItemBrand
          v-for="(item, index) in brands?.data?.items"
          :key="`item_${index}`"
          class="brands-default-carousel__slider--brands"
          :item="item"
        />
      </div>

      <CaIconButton
        v-if="$device.isDesktop"
        class="ml-4"
        icon="arrow-right"
        size="small"
        aria-label="avançar"
        :disabled="scrollRightDisabled"
        @click="scroll('right')"
      />
    </div>
  </div>
</template>

<script setup>
const scrollLeftDisabled = ref(true);
const scrollRightDisabled = ref(false);
const slider = ref(null);

const id = '283fe73e-2633-4e32-824e-b2d3a9b3c8bc';
const { data: brands } = await useLabradorFetch(
  `/v2/new-home/component/${id}`,
  {
    key: 'brands',
    lazy: true,
  },
);

const scroll = (direction) => {
  const widthToScroll = 900;
  slider.value.scrollLeft
    += direction === 'right' ? widthToScroll : -widthToScroll;
  setTimeout(() => updateControllers(), 700);
};

const updateControllers = () => {
  if (!slider.value) return;

  const finalWidth = slider.value.scrollWidth - slider.value.offsetWidth;
  scrollLeftDisabled.value = slider.value.scrollLeft < 10;
  scrollRightDisabled.value = slider.value.scrollLeft > finalWidth - 10;
};

onMounted(() => {
  updateControllers();
});
</script>

<style scoped lang="scss">
@import './style.scss';
</style>
