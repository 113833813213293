<template>
  <div class="breeds">
    <div class="breeds__header">
      <CaTitle
        tag="h2"
        size="s"
        :class="['title-black mb-5', $device.isMobile && 'text-center']"
        color="primary"
      >
        {{ data.title }}
      </CaTitle>

      <CaLink
        class="all-breeds"
        href="/racas"
        color="primary"
      >
        Ver todas
      </CaLink>
    </div>

    <ul class="breeds__list">
      <li
        v-for="breed in data.items"
        :key="breed.name"
        class="breeds__list__item"
        @click="handleClick(breed, item)"
      >
        <a :href="breed.link">
          <img
            loading="lazy"
            :src="breed.img"
            :alt="`imagem de um cachorro da raça ${breed.name}`"
            class="breeds__list__item__image"
            :width="113"
            :height="113"
          >
          <p class="breeds__list__item__name">
            {{ breed.name }}
          </p>
        </a>
      </li>
    </ul>

    <div class="breeds__footer">
      <CaLink
        href="/racas"
        color="primary"
      >
        Ver todas
      </CaLink>
    </div>
  </div>
</template>

<script>
import { slugify } from '@petlove/frontend-utilities-utils';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClick(breed, type) {
      const eventAction = `componente-seo:card:${slugify(type.name)}:${slugify(
        breed.name,
      )}`;

      this.$gtm.dispatchEvent({
        event_category: 'click',
        event_action: eventAction,
        event_label: 'home',
      });

      window.open(`${breed.slug}/r`, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.breeds {
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-breakpoint) {
    align-items: center;
  }

  @media (min-width: $desktop-breakpoint) {
    align-items: baseline;
    gap: space(3);
  }

  &__list {
    display: flex;
    overflow-x: auto;
    gap: space(3);
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: $tablet-breakpoint) {
      padding-left: space(5);
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      &:last-child {
        margin-right: space(5);
      }
      &__image {
        border-radius: rounded(l);
        object-fit: cover;
        width: 99px;
        height: 99px;
      }
      &__name {
        color: color(neutral, dark);
        font-weight: fontWeight(medium);
        font-size: fontSize(body-s);
        text-align: center;
        word-wrap: break-word;
        overflow-wrap: break-word;
        width: 70px;
        margin: space(3) auto auto auto;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    a {
      font-weight: bold;

      @media (max-width: $desktop-breakpoint) {
        display: none;
      }
    }

    @media (max-width: $tablet-breakpoint) {
      text-align: center;
      display: block;
    }
  }

  &__footer {
    margin-bottom: space(3);
    text-align: center;
    display: block;
    width: 100%;

    a {
      font-weight: bold;
    }

    @media (min-width: $desktop-breakpoint) {
      display: none;
    }
  }
}
</style>
