<template>
  <div class="high-carousel">
    <div
      ref="slider"
      class="high-carousel__slider py-2"
    >
      <div
        ref="sliderContent"
        class="flex slider-content"
        :style="{ margin: `0 ${spaceWidth}px` }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighCarousel',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
    countItems: {
      type: Number,
      default: 0,
    },
    interval: {
      type: Number,
      default: 5000,
    },
  },
  emits: ['input', 'update:modelValue'],
  data() {
    return {
      timeStart: null,
      itemWidth: 0,
      itemPositions: [],
      spaceWidth: 0,
      intervalId: null,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        if (newValue < 0) {
          newValue = 0;
        }
        this.$emit('input', newValue);
        this.$emit('update:modelValue', newValue);
        this.updateItemsClass(newValue);
      },
    },
  },
  watch: {
    modelValue(value) {
      this.gotoIndex(value);
    },
  },
  mounted() {
    this.onScrollStop(this.$refs['slider'], this.scrollStop);
    this.gotoIndex(this.value);

    if (this.infinite) {
      this.setupInterval();
    }
  },
  beforeUnmount() {
    if (this.infinite) {
      this.clearInterval();
    }
  },
  methods: {
    updateItemsClass(activeIndex) {
      this.$children?.forEach((item, idx) => {
        const classList = (item.$el || item)?.classList;
        classList[idx === activeIndex ? 'add' : 'remove']('active');
        if (!classList.contains('item')) {
          classList.add('item');
        }
      });
    },
    previous() {
      const { scrollLeft, offsetWidth } = this.$refs['slider'];
      this.$refs['slider'].scroll({
        left: scrollLeft - offsetWidth,
        behavior: 'smooth',
      });
    },

    next() {
      const { scrollLeft, offsetWidth } = this.$refs['slider'];
      this.$refs['slider'].scroll({
        left: scrollLeft + offsetWidth,
        behavior: 'smooth',
      });
    },
    onScrollStop(ref, callback) {
      let timeScrolling;
      ref.addEventListener(
        'scroll',
        (e) => {
          clearTimeout(timeScrolling);
          timeScrolling = setTimeout(() => {
            callback(e);
          }, 150);
        },
        false,
      );
    },
    scrollTo(position) {
      this.$refs['slider'].scroll({
        left: position + this.spaceWidth,
        behavior: 'smooth',
      });
    },
    gotoIndex(index) {
      const { offsetWidth: contentWidth } = this.$refs['sliderContent'];
      const { offsetWidth: sliderWidth } = this.$refs['slider'];
      const quantityItems = this.$children?.length;
      if (!quantityItems) return;
      this.itemWidth = contentWidth / quantityItems;
      this.spaceWidth = (sliderWidth - this.itemWidth) / 2;
      if (index >= quantityItems) {
        index = quantityItems - 1;
      }
      else if (index < 0) {
        index = 0;
      }
      this.itemPositions = Array(quantityItems)
        .fill(0)
        .map((value, idx) => idx * this.itemWidth);
      const middleItem = this.itemWidth / 2;
      const startItemPosition = this.itemPositions[index];
      const centerItem = startItemPosition + middleItem;
      const indexPosition = centerItem - sliderWidth / 2;
      this.scrollTo(indexPosition);
      this.value = index;
    },
    scrollStop() {
      const { scrollLeft, offsetWidth: sliderWidth } = this.$refs['slider'];
      const center = scrollLeft + sliderWidth / 2;
      const index = this.itemPositions.findIndex(
        pos =>
          center >= pos + this.spaceWidth
          && center < pos + this.spaceWidth + this.itemWidth,
      );
      this.gotoIndex(index);
    },
    setupInterval() {
      this.intervalId = setInterval(() => {
        if (this.modelValue === this.countItems - 1) {
          this.gotoIndex(0);
        }
        else {
          this.gotoIndex(this.modelValue + 1);
        }
      }, this.interval);
    },
    clearInterval() {
      clearInterval(this.intervalId);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./style.scss";
</style>
