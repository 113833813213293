<template>
  <div class="main-title">
    <h1>Petlove: o maior petshop online do Brasil</h1>
  </div>
</template>

<style lang="scss">
.main-title {
  width: 100%;
  background-color: color(neutral, light);
  padding: space(7) 0;
  text-align: center;
  color: color(primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: $font-size-title-xs;
  margin-bottom: space(7);

  @media (max-width: $tablet-breakpoint) {
    padding: space(5) space(13);
    font-size: $font-size-body;
  }

}
</style>
