<template>
  <div class="card">
    <div
      class="card__content"
      :class="{ 'card__content--desktop': $device.isDesktop }"
    >
      <div>
        <span class="card__content__title">{{ item.card.title }}</span>
        <CaTitle
          class="font-black mb-4 card__content__subtitle"
          :class="{ 'card__content__subtitle--active': isActive }"
          weight="black"
          color="primary"
          tag="h3"
        >
          {{ item.card.subtitle }}
        </CaTitle>

        <CaButton
          v-if="$device.isDesktop"
          type="primary"
          :size="isActive ? 'large' : 'small'"
          full-width
          @click="$emit('click', item.card)"
        >
          {{ item.card.link_info }}
        </CaButton>
      </div>

      <img
        loading="lazy"
        class="card__content__image"
        :class="{ 'card__content__image--active': isActive }"
        :src="item.card.image"
        :alt="item.card.title"
        width="168"
        height="168"
      >

      <CaButton
        v-if="$device.isMobile"
        type="primary"
        :size="isActive ? 'large' : 'small'"
        full-width
        @click="$emit('click', item.card)"
      >
        {{ item.card.link_info }}
      </CaButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click'],
};
</script>

<style lang="scss" scoped>
.card {
  background-color: color(neutral, light);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: space(3);

    &--desktop {
      flex-direction: row;
    }

    &__title {
      color: red;
      font-weight: fontWeight(bold);
    }

    &__subtitle {
      line-height: 120%;
      font-size: fontSize(body);

      &--active {
        font-size: fontSize(title-s);
      }

      @media (min-width: $desktop-breakpoint) {
        font-size: fontSize(body);

        &--active {
          font-size: fontSize(title-s);
        }
      }
    }

    &__image {
      width: 140px;

      &--active {
        width: 169px;
      }

      @media (min-width: $desktop-breakpoint) {
        width: 140px;

        &--active {
          width: 169px;
        }
      }
    }
  }
}
</style>
