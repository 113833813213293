<template>
  <div
    v-if="showCarousel"
    class="offerstwo"
  >
    <WsObserveVisibility
      id="banner-offers-secondary"
      v-slot="{ show }"
      @is-visible="bannerViewEvent"
    >
      <HighCarousel
        v-if="show && $device.isMobile"
        ref="highCarousel"
        v-model="currentIndex"
        class="offerstwo-highcarousel mt-7 mb-7"
        infinite
        :count-items="items.length"
      >
        <a
          v-for="(item, index) in items"
          ref="item"
          :key="index"
          :href="item.target"
          class="offerstwo-highcarousel__item"
          @click="handleClick(item, $event)"
        >
          <img
            loading="lazy"
            :src="item.image"
            class="offerstwo-highcarousel__image rounded-l"
          >
        </a>
      </HighCarousel>
      <Carousel
        v-if="show && $device.isDesktop"
        class="offerstwo-carousel"
        full-width
        :controls="items.length > 4"
        :count-items="items.length"
        infinite
        @change="bannerViewEvent($event)"
      >
        <div class="offerstwo-carousel__banners flex full-width">
          <div
            v-for="(item, index) in items"
            ref="item"
            :key="index"
            class="offerstwo-carousel__item cursor-pointer"
            @click="handleClick(item, $event)"
          >
            <div class="offerstwo-carousel__wrap-image">
              <img
                loading="lazy"
                :src="item.image"
                class="offerstwo-carousel__image rounded-l"
              >
            </div>
          </div>
        </div>
      </Carousel>
    </WsObserveVisibility>
  </div>
</template>

<script>
import { dispatchPromotionsEvent } from '@petlove/lion/libs/gtm-promotions';
import { dispatchPromotionsGA4 } from '@petlove/lion/libs/ga4-promotions';

export default {
  name: 'BannerOffersSecondary',
  setup() {
    const { getDYChoose, reportDYClick } = useDynamicYield();

    return { getDYChoose, reportDYClick };
  },
  data() {
    return {
      selectedCategory: '',
      currentIndex: 1,
      timeSelect: null,
      scrolling: false,
      showCarousel: false,
      actualSlide: 0,
      transitionEvent: {},
      dyBanners: [],
      dyDecisionId: '',
      dyRequestCompleted: false,
      dyType: '',
      hcItemsRef: [],
      data: {
        experiment: 'banner-offers-secondary',
        fallback: {
          image:
            'https://www.petlove.com.br/static/uploads/banner_image/image/50563/Secund_rio___Desktop___Mobile.png',
          link: 'https://www.petlove.com.br/colecao/pet-friday-dos-sonhos-com-descontos-imperdiveis',
        },
      },
    };
  },
  computed: {
    items() {
      return this.dyBanners;
    },
  },
  watch: {
    currentIndex() {
      this.bannerViewEvent();
    },
  },
  async mounted() {
    this.dyBanners = await this.loadDYBanners();
    this.$refs.highCarousel?.gotoIndex(this.currentIndex);
    this.hcItemsRef = this.$refs.item;
  },
  methods: {
    async handleClick(item, $event) {
      $event?.preventDefault();

      try {
        dispatchPromotionsEvent('click', [item], 'carrossel-ofertas-II');
        await this.reportDYClick(this.dyDecisionId, item.id);
      }
      catch (error) {
        console.error('Error on click event:', error);
      }
      finally {
        const target = item.target?.includes('petlove.com.br')
          ? '_self'
          : '_blank';
        window.open(item?.target, target);
      }
    },
    async loadDYBanners() {
      const payload = {
        experiment: this.data?.experiment,
        pageType: 'HOMEPAGE',
      };
      try {
        const { choices } = await this.getDYChoose(payload);
        const { decisionId, type, variations } = choices[0];

        this.dyDecisionId = decisionId;
        this.dyType = type;

        const updatedVariations = this.updateVariationsWithFallback(variations);
        this.dyBanners = this.getDynamicYieldBanners(updatedVariations);
      }
      catch (error) {
        const errorDetails = {
          name: 'Empty Recommendations',
          component: 'BannerSecondary',
          experiment: this.data.experiment || '',
          pageType: payload.pageType,
          error: error.message,
        };
        console.error('Dynamic Yield Experiment failed:', error);

        this.$honeybadger?.notify(error, errorDetails);

        const updatedVariations = this.updateVariationsWithFallback();
        this.dyBanners = this.getDynamicYieldBanners(updatedVariations);
      }
      finally {
        this.dyRequestCompleted = true;
      }
      this.showCarousel = !!this.dyBanners.length;
      return this.dyBanners;
    },
    getDynamicYieldBanners(data) {
      const device = this.$device.isMobile ? 'mobile' : 'desktop';
      const image = `image_${device}`;
      const banners = data?.filter(variation => variation?.payload?.data?.[image]).map((variation) => {
        const { data } = variation.payload;
        return {
          id: data?.id_ov,
          banner_type: data?.banner_type || '',
          name: data?.bn_name,
          creative: data?.creative_name,
          target: data?.target,
          image: data?.[image],
          position: data?.position,
          supplier: data?.supplier,
        };
      }) || [];

      return banners.sort((banner1, banner2) => {
        if (!banner1.position) return 1;
        if (!banner2.position) return -1;
        return banner1.position - banner2.position;
      });
    },
    updateVariationsWithFallback(variations) {
      try {
        if (!variations || variations.length === 0) {
          const payload = [
            {
              payload: {
                data: {
                  image_mobile: this.data.fallback?.image,
                  image_desktop: this.data.fallback?.image,
                  target: this.data.fallback?.link,
                },
              },
            },
          ];
          const replicatedPayload = Array.from({ length: 4 }, () => payload[0]);
          return replicatedPayload;
        }

        return variations.map((variation) => {
          if (!variation || !variation.payload || !variation.payload.data) {
            throw new Error('Variation data is missing or invalid.');
          }

          const { payload } = variation;
          const { data } = payload;
          const { image_mobile, image_desktop, target } = data;

          const fallbackData = {
            image_mobile: image_mobile || this.data.fallback?.image,
            image_desktop: image_desktop || this.data.fallback?.image,
            target: target || this.data.fallback?.link,
          };

          return {
            ...variation,
            payload: {
              ...payload,
              data: {
                ...data,
                ...fallbackData,
              },
            },
          };
        });
      }
      catch (error) {
        this.$honeybadger?.notify(error, {
          name: 'Fallback Error',
          component: 'BannerSecondary',
          pageType: 'HOMEPAGE',
          error: error?.message,
        });
        throw error;
      }
    },
    bannerViewEvent(page = 1) {
      if (this.$device.isMobile) {
        const item = [
          {
            ...this.items[this.currentIndex],
            position: this.currentIndex,
          },
        ];
        if (item.length) {
          dispatchPromotionsEvent('view', item, 'carrossel-ofertas-II');
          dispatchPromotionsGA4('view', item, 'carrossel-ofertas-II');
        }
      }

      if (this.$device.isDesktop) {
        const itemsPerPage = 4;
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const rawItems = this.items.slice(startIndex, endIndex);
        const items = rawItems.map((item, index) => ({
          ...item,
          position: startIndex + index,
        }));
        dispatchPromotionsEvent('view', items, 'carrossel-ofertas-II');
        dispatchPromotionsGA4('view', items, 'carrossel-ofertas-II');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.offerstwo {
  margin: space(10) auto;
  max-width: 1050px;

  @media (min-width: $tablet-breakpoint) {
    margin: space(13) auto space(10) auto;
  }

  .offerstwo-highcarousel {
    &__image,
    &__item {
      width: 20rem;
      height: 20rem;
    }

    &__image {
      object-fit: cover;
    }
  }
  .offerstwo-carousel {
    &__wrap-image {
      position: relative;
      height: 0;
      padding-bottom: 100%;
      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
    &__item {
      flex: 25%;
      padding: 1.2rem;
      flex-shrink: 0;
      max-width: 33%;
    }
    &__image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
