<template>
  <div
    v-if="!$device.isDesktop"
    datatest-id="banner-app"
    class="my-10 flex justify-content-center"
    @click="downloadApp"
  >
    <img
      loading="lazy"
      width="320"
      height="100"
      src="https://www.petlove.com.br/static/uploads/new-home/banner-app.jpg"
      alt="baixe o app e tenha descontos exclusivos"
      class="full-width"
    >
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      window.open('https://petlove.onelink.me/419198568/86e9560f', '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
img {
  height: auto;
}
</style>
